<template>
  <b-container fluid class="login-2022 mx-auto">
    <b-row class="vh-100" align-v="center">
      <b-col cols="4" class="l-left">
        <b-row class="flex-column h-100 ml-4 mr-5">
          <b-col cols="2" class="d-flex align-items-center">
            <div class="logo-black"> </div>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="8" class="l-right">
        <b-row class="h-100 align-items-center" align-v="center">
          <div class="mx-auto login-action text-center">
            <div class="ee-logo mx-auto"> </div>
            <h1 class="my-3">Password Reset</h1>
            <p  v-if="!resetRequestSent" class="text-center text-muted small p-0 m-0 pb-2 px-1">We’ll email you instructions on how to reset it.</p>
            <b-form  v-if="!resetRequestSent" @submit="resetPassword" v-on:submit.prevent class="credentials-form" :class="{'larkin-form-dirty': $v.input.$dirty}">
              <div class="invalid-feedback d-block" v-if="formError()">
                {{formErrorMessage()}}
              </div>

              <b-form-group label-for="code" class="my-2">
                <b-input-group>
                  <b-form-input v-model="$v.input.login.$model" type="text" placeholder="Email Address" @change="clearErrors()"></b-form-input>
                </b-input-group>
                <div class="invalid-feedback d-block" v-if="submitted && $v.input.login.$invalid">
                  <span v-if="!$v.input.login.required">Email is required</span>
                </div>
              </b-form-group>

              <b-btn :disabled="isSubmitting" type="submit" class="btn-larkin-yellow btn-full">
                <span class="login-text">Reset Password</span>
                <div v-if="isSubmitting" v-bind:class="{'local-spinner-border':isSubmitting, 'local-spinner-spacer':!isSubmitting}"/>
              </b-btn>
            </b-form>
            <div v-else>
              <h6 class="text-center text-secondary">We have emailed you a link to enable you to reset your password.</h6>
            </div>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'
  import { required } from 'vuelidate/lib/validators'

  export default {
    name: 'resetPassword',
    mixins: [FormMixin],
    data: function() {
      return {
        input: {
          login: '',
          userLevel: null
        },
        isSubmitting: false,
        resetRequestSent: false
      }
    },
    validations: {
      input: {
        login: {
          required
        }
      }
    },
    methods: {
      resetPassword: function() {
        this.isSubmitting = !this.$v.$invalid;

        // Update the CSRF
        this.$root.updateAuthStatus()
          .then(() => {
            this.submit('post', ApiProperties.api.resetPassword, {}, this.input, () => {
              this.resetRequestSent = true;
              this.isSubmitting = false;
            }, () => {
              this.isSubmitting = false;
            })
          })
      }
    },

    created() {
      const intent = (this.$route.query.intent) ? this.$route.query.intent : 'ee'
      const userLevel = this.$constants.UserIntent.getByPath(intent)
      this.input.userLevel = userLevel.userType
    }
  }
</script>
<style lang="scss">
  @import '../scss/login-pages';
</style>
